import React, { useState } from "react";
import "./index.scss";

//temp
import vid1 from "./assets/behind_1.mp4";
import vid2 from "./assets/behind.mp4";
import vid3 from "./assets/behind_3.mp4";

const Behind_the_scenes = () => {
  const [isOpen, setOpen] = useState(0);

  return (
    <section id="behind_the_scenes">
      <h1>Behind the Scenes</h1>
      <div id="vid-gallery">
        <video
          id="hand_carfted_3"
          width={"100%"}
          autoPlay={"yes"}
          muted={"yes"}
          loop={"yes"}
          onClick={function () {
            setOpen(vid1);
          }}>
          <source src={vid1} />
        </video>
        <video
          id="hand_carfted_3"
          width={"100%"}
          autoPlay={"yes"}
          muted={"yes"}
          loop={"yes"}
          onClick={function () {
            setOpen(vid2);
          }}>
          <source src={vid2} />
        </video>
        <video
          id="hand_carfted_3"
          width={"100%"}
          autoPlay={"yes"}
          muted={"yes"}
          loop={"yes"}
          onClick={function () {
            setOpen(vid3);
          }}>
          <source src={vid3} />
        </video>
      </div>
      {isOpen != 0 && (
        <div>
          <Modal image={isOpen} setOpen={setOpen} />
        </div>
      )}
    </section>
  );
};

export default Behind_the_scenes;

const Modal = ({ image, setOpen }) => {
  return (
    <div
      id="vidmodal"
      onClick={function () {
        setOpen(0);
      }}>
      <video
        id="hand_carfted_3"
        width={"100%"}
        autoPlay={"yes"}
        muted={"yes"}
        loop={"yes"}>
        <source src={image} />
      </video>
    </div>
  );
};
