import React from "react";
import "./index.scss";

const Intro = () => {
  return (
    <div id="intro">
      <div className="text">
        <q>
          Food is the language of the Mediterranean, each dish a
          story whispered from ancient kitchens, carrying the
          essence of sun-drenched lands and sea-kissed shores
        </q>
      </div>
    </div>
  );
};

export default Intro;
