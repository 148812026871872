import React from "react";
import "./index.scss";

const Contact = () => {
  return (
    <div id="contactForm">
      <div id="title" className="title">
        <h2>Contact Us</h2>
      </div>

      <form
        name="contact"
        data-netlify="true"
        method="post"
        id="form">
        <input type="hidden" name="form-name" value="contact" />
        <label for="name">
          Name
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Your name.."
            required
          />
        </label>

        <label for="email">
          Email
          <input
            type="text"
            id="email"
            name="email"
            placeholder="Your email address..."
            required
          />
        </label>

        <label for="subject">
          Subject
          <textarea
            id="subject"
            name="subject"
            placeholder="Write something.."></textarea>
        </label>

        <input type="submit" value="Submit" />
      </form>

      <div id="team-at" className="desktop-only">
        <p>team@shafiks.com</p>
      </div>
    </div>
  );
};

export default Contact;
