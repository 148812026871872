import React from "react";
import "./index.scss";

import arch_image from "./assets/shafik_profile.png";
import food_image from "./assets/food_2.jpg";
import food_1 from "./assets/food_1.jpg";

const Legacy = (text) => {
  return (
    <div id="legacy">
      <h1 id="header">
        40-year legacy <br />
        of excellence
      </h1>
      <div id="text">
        Shafik Confectionary is celebrated for its superior
        craftsmanship in creating exquisite Knafeh, assorted
        Baklava products, and other Mediterranean sweets.
        Originally founded in Dalit El Carmel.
      </div>

      <div id="arch" className="bottom">
        <img className="image-arch" src={arch_image} alt="" />
      </div>

      <div id="sprinkle">
        <img className="image-arch" src={food_image} alt="" />
      </div>

      <div id="food_top">
        <img className="desktop_only" src={food_1} alt="" />{" "}
      </div>
    </div>
  );
};

export default Legacy;
