import React from "react";
import "./index.scss";
import videoRoll from "./assets/homehero.vp9";

const VideoSection = () => {
  return (
    <div id="videoSection">
      <video
        width={"100%"}
        autoPlay={"yes"}
        muted={"yes"}
        loop={"yes"}>
        <source src={videoRoll} />
      </video>
    </div>
  );
};

export default VideoSection;
