import React from "react";
import "./index.scss";

import image_1 from "./assets/D_food1.png";
import image_2 from "./assets/D_food2.png";
import image_3 from "./assets/D_food3.png";

let LowCalorie = () => {
  return (
    <section id="low-calorie">
      <div id="plain-text">
        we proudly present a selection of
      </div>
      <h2 id="low-calorie">Low Calorie</h2>
      <h2 id="gluten-free">Gluten Free</h2>
      <div id="images">
        <div id="image_2">
          <img src={image_2} />
        </div>
        <div id="image_3">
          <img src={image_3} />
        </div>
        <div id="image_1" className="desktop-only">
          <img src={image_1} />
        </div>
      </div>
    </section>
  );
};

export default LowCalorie;
