import React from "react";

const SiteFooter = () => {
  return (
    <footer>
      <p>All Rights Reserved Shafik Mediterranean Culinary of Art 2024</p>
    </footer>
  );
};

export default SiteFooter;
